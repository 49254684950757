<template>
  <div>
    <CommonStandardList
        slug="Company Group"
        module-name="cm-group"
        :table-headers="tableHeaders"
        :form-setting="form"
        :api-urls="apiUrls"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {onUnmounted, ref} from "@vue/composition-api"

// sidebar
import CommonStandardList from "@/views/common/list/standard-list/CommonStandardList"
import usePermissionsList from "@/views/access-management/permissions/permission-list/usePermissionsList"

export default {
  components: {
    CommonStandardList
  },
  setup() {

    const userStatuses = ref([
      {text: "Active", value: "ACTIVE"},
      {text: "Inactive", value: "INACTIVE"},
      {text: "Blocked", value: "BLOCKED"},
    ])

    const apiUrls = ref(
        {
          list: "/admin/company-group",
          add: "/admin/company-group"
        }
    )

    const tableHeaders = ref([
      {text: "NAME", value: "name", filter: true},
      {text: "ADDRESS", value: "address"},
      {text: "CREATED AT", value: "created_at", sortable: false},
      {
        text: "ACTIONS",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ])

    const form = ref([
      {name: "name", type: "text", required: true},
      {name: "address", type: "text", required: true},
    ])

    const searches = []

    return {
      tableHeaders,
      form,
      apiUrls,
      // icons
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
